import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import PageFurther from "./page-further"

const PageFurtherGutachten = ( { className = '', removeId }) => {

  const data = useStaticQuery(graphql`
      query PageFurtherGutachtenQuery {
        allWpPage(filter: {acf: {art: {in: [ "gutachten" ] }}}) {
        
          nodes {
            id
            acf {
              art
              description
              thumbnail {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            slug
            title
          }
        }
      }
      `);

  return <PageFurther className={className} nodes={data.allWpPage.nodes.filter(n => n.id !== removeId )} />

};

export default PageFurtherGutachten;
