import React, { useState } from "react"
import { Formik } from "formik"
import axios from "axios"
import { getStrapiUrl } from "../../helper/uri"
import { toast } from 'react-toastify';
import * as styles from "../betriebsmarkt/betriebsmarkt-contact.module.scss"
import RcFormErrors from "../../page-components/helper/rcFormErrors"

import "react-datepicker/dist/react-datepicker.css";
import DatepickerField from "../../components/datepicker-field/datepicker-field"
import * as Yup from "yup"
import { formatDate } from "../../helper/date"

const CompanyEval = () => {

  const [ sent, setSent ] = useState(false);

  const formSchema = Yup.object().shape(
    {
      mail: Yup.string().email("Ungültige Mail-Adresse").required("E-Mail Adresse notwendig"),
      name: Yup.string().required("Ihr vollständiger Name ist notwendig"),
      telefon: Yup.string().required("Ihre Telefonnummer ist notwendig")
    }
  );


  return <>

    {
      sent &&
        <div  className={"my-4"}>
          <h3>Anfrage erfolgreich</h3>
          <p>Vielen Dank für Ihre Anfrage. Wir melden uns bald bei Ihnen.</p>
        </div>
    }

    {!sent &&

    <Formik
      validationSchema={formSchema}
      initialValues={
        {
          mail: '',
          name: '',
          telefon: '',

          wannVerkauf: '',
          branche: '',

          kontaktArt: '',
          wunschUhrzeit: '',

          datum: '',
          datum2: '',
          datum3: '',

          umsatz: '',
          betriebsErgebnis: '',

          address: ''
        }
      }
      onSubmit={async (values, { setSubmitting }) => {

        if (!values.branche || values.branche === "invalid") {
          toast("Bitte geben Sie eine gültige Branchengruppe an");
          return;
        }

        if (!values.wannVerkauf || values.wannVerkauf === "invalid") {
          toast("Bitte geben Sie an wann genau Sie Ihr Unternehmen verkaufen wollen");
          return;
        }

        if (!values.kontaktArt || values.kontaktArt === "invalid") {
          toast("Bitte geben Sie Ihre gewünschte Kontaktart an.");
          return;
        }

        const dates = [];
        if (values.datum) dates.push( formatDate(values.datum) );
        if (values.datum2) dates.push( formatDate(values.datum2) );
        if (values.datum3) dates.push( formatDate(values.datum3) );

        values = {
          ...values,
          dates: dates.join(", ")
        };

        try {
          await axios.post(getStrapiUrl() + "/submit-contact/company-eval", { art: "Unternehmens-Bewertung",  ...values });
          setSubmitting(false);
          setSent(true);
        } catch (err) {
          toast("Es ist ein unbekannter Fehler passiert.");
        }

      }}
    >
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
        <form className={"rc-form row mb-5"} onSubmit={handleSubmit}>


          <div className={"col-12 col-lg-6"}>

            <h5>Informationen über das Unternehmen</h5>

            <div className="rc-form-control">
              <label>Ordnen Sie Ihr Unternehmen bitte in eine der folgenden Branchengruppen ein.</label>

              <select name={"branche"} onChange={handleChange} onBlur={handleBlur} value={values.branche}>
                <option value={"invalid"}>Bitte wählen Sie aus</option>
                <option>Dienstleistung</option>
                <option>Handel</option>
                <option>Produktion</option>
                <option>Industrie</option>
                <option>IT/Software</option>
                <option>Sonstiges</option>
              </select>

            </div>

            <div className="rc-form-control">
              <label>Wann möchten Sie Ihr Unternehmen verkaufen bzw. übergeben?</label>
              <select name={"wannVerkauf"} onChange={handleChange} onBlur={handleBlur} value={values.wannVerkauf}>
                <option value="invalid">Bitte wählen Sie aus</option>
                <option>schnellstens</option>
                <option>in diesem Jahr</option>
                <option>später</option>
              </select>
            </div>

            <div className={"rc-form-control"}>
              <label>Geben Sie bitte den Umsatz Ihres Unternehmens an</label>
              <input type={"number"} name={"umsatz"} onChange={handleChange} onBlur={handleBlur} value={values.umsatz} />
            </div>

            <div className={"rc-form-control"}>
              <label>Geben Sie bitte das Betriebsergebnis (EBIT) Ihres Unternehmens an</label>
              <input type={"number"} name={"betriebsErgebnis"} onChange={handleChange} onBlur={handleBlur} value={values.betriebsErgebnis} />
            </div>

            <div className="rc-form-control">
              <label htmlFor="">Adresse Ihres Unternehmensstandortes</label>
              <input type={"text"} placeholder={"Musterstraße 1, 1234 Musterstadt"} name={"address"}
                     onChange={handleChange} onBlur={handleBlur} value={values.address} />
            </div>

          </div>

          <div className={"col-12 col-lg-6"}>
            <h5>Kontakt</h5>

            <p className={styles.info}>Ab wann und wie wollen Sie kontaktiert werden?</p>

            <div className="rc-form-control">
              <label>Name*</label>

              <input
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />

            </div>

            <div className="rc-form-control">
              <label htmlFor="mail">E-Mail*</label>

              <input
                type="email"
                id={"mail"}
                name="mail"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mail}
              />

              {errors.email && touched.email && errors.email}
            </div>

            <div className="rc-form-control">
              <label>Telefon*</label>

              <input
                type="phone"
                name="telefon"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.telefon}
              />

            </div>

            <div className={"rc-form-control"}>
              <label>Wunschtermine</label>
              <DatepickerField name={"datum"}/>
              <DatepickerField className={"mt-2"} name={"datum2"}/>
              <DatepickerField className={"mt-2"} name={"datum3"}/>
            </div>

            <div className="rc-form-control">
              <label>Wunschuhrzeit</label>
              <input type={"text"} placeholder={"von - bis"} name={"wunschUhrzeit"}
                     onChange={handleChange} onBlur={handleBlur} value={values.wunschUhrzeit} />
            </div>

            <div className="rc-form-control">
              <label>Gewünschte Kontaktart</label>

              <select name={"kontaktArt"} value={values.kontaktArt}
                      onBlur={handleBlur} onChange={handleChange}>

                <option value={"invalid"}>Bitte wählen Sie aus</option>
                <option>Telefon</option>
                <option>persönlicher Termin</option>
                <option>Videokonferenz</option>

              </select>

            </div>




            <RcFormErrors touched={touched} errors={errors} className={"mb-4"} />
            <button type="submit" disabled={isSubmitting}>Absenden</button>


            <div className={styles.appendix + " mt-5"}>
              *) Sämtliche uns übermittelten Informationen und Daten werden diskret und anonym behandelt. Sie werden
              auch nicht weitergegeben oder längerfristig gespeichert.
            </div>


          </div>

        </form>
      )}


    </Formik>
    }

  </>;

};

export default CompanyEval;
