import React, { useRef, useState } from "react"
import * as styles from "./betriebskosten-check.module.scss";

import { graphql, useStaticQuery } from "gatsby"

import ChevronDownSVG from "../../icons/fontawesome/light/chevron-down.svg";
import { getStrapiUrl } from "../../helper/uri"
import axios from "axios"
import RcButton from "../../components/button/rcButton"

const BetriebskostenSection = ( { title, children }) => {

  const [ open, setOpen ] = useState(true);

  return <div className={"mt-5 p-4 " + styles.section}>
    <div className={"d-flex justify-content-between"}>
      <h4>{title}</h4>
      <ChevronDownSVG className={"c-pointer " + styles.openCloseSVG + " " + (open ? "" : styles.notOpenSVG)}
                      onClick={ () => setOpen(o => !o) } />
    </div>

    { open && children }
  </div>

}

const BetriebskostenElement = (
                                {
                                  disabled = false,
                                  children,
                                  subtitle,
                                  mark = () => { },
                                  unmark = () => { }
                                }
                              ) => {
  return <div className={"d-flex align-items-center my-3 " + styles.checkboxWrapper}>
    <input type={"checkbox"}
           className={"mr-2"}
           disabled={disabled}
           onChange={
             (e) => {
               const checked = e.target.checked;

               if (checked) {
                 mark(children);
               } else {
                 unmark(children);
               }
             }
           }
    />

    <div className="d-flex flex-column">
      <p>{ children }</p>

      {subtitle &&
      <p className={styles.subTitle}>{subtitle}</p>
      }
    </div>

  </div>
}

const BetriebskostenEmptyElement = ( { idx = -1, change = () => { } } ) => {
  return <div className="d-flex align-items-center my-2">

    <input
        type={"text"} className={styles.input} placeholder={"zusätzliche Betriebskosten"}
          onChange={ (e) => {
            const input = e.target.value;
            change(idx, input);
          }
        }
    />
  </div>
}

const BetriebskostenCheck = () => {

  const data = useStaticQuery(
    graphql`
    query BetriebskostenCheckPage {
       wp {
          betriebskostenCheck {
            acf {
              sections {
                elements {
                  title
                  subtitle
                  legit
                }
                sectiontitle
              }
            }
          }
  
          texteFRSektionen {
            acf {
              immointroduction
              introbetriebskostencheck
            }
          }
        }
    }
    `
  );

  const sections = data.wp.betriebskostenCheck.acf.sections;

  const elements = sections.reduce(
    (acc, cur) => {
      return [ ...acc, ...cur.elements.map(e => { return { ...e, section: cur.sectiontitle }; }) ]
    },
    []
  );

  const [ ownElements, setOwnElements ] = useState([]);

  const mark = (element) => {
    setOwnElements( [ ...ownElements, element ] );
  };

  const unmark = (element) => {
    setOwnElements( ownElements.filter(e => e !== element) )
  };

  const [ state, setState ] = useState('initial');

  const [ legit, setLegit ] = useState(0);
  const [ legitElements, setLegitElements ] = useState([]);

  const [ notLegit, setNotLegit ] = useState(0);
  const [ notLegitElements, setNotLegitElements ] = useState([]);

  const [ legitButNotMarked, setLegitButNotMarked ] = useState(0);
  const [ legitButNotMarkedElements, setLegitButNotMarkedElements ] = useState([]);

  const [ freetextElements, setFreetextElements ] = useState([]);

  const mailInputRef = useRef(null);

  const [ calculated, setCalculated ] = useState(false);

  const calculate = () => {

    setCalculated(true);

    let notLegit = 0;
    let legit = 0;
    let legitButNotMarked = 0;

    const notLegitElements = [];
    const legitElements = [];
    const legitButNotMarkedElements = [];


    for (const e of elements) {

      if ( ownElements.indexOf(e.title) !== -1 ) {
        // marked as own

        if (e.legit) {
          legit++;
          legitElements.push(e);
        } else {
          notLegit++;
          notLegitElements.push(e);
        }

      } else {
        // not marked as own
        if (e.legit) {
          legitButNotMarked++;
          legitButNotMarkedElements.push(e);
        } else {

        }
      }

      setLegit(legit);
      setLegitButNotMarked(legitButNotMarked);
      setNotLegit(notLegit);

      setLegitElements(legitElements);
      setLegitButNotMarkedElements(legitButNotMarkedElements);
      setNotLegitElements(notLegitElements);
    }

  }

  return  <>
          { sections.map( (s, i) => {
            return <BetriebskostenSection title={s.sectiontitle}>
              { s.elements.map( e => {
                return <BetriebskostenElement
                              subtitle={e.subtitle}
                              disabled={state === 'success'} mark={mark} unmark={unmark}>
                  { e.title }
                </BetriebskostenElement>
              } )}

              {
                [0, 1, 2].map((innerIndex) => {
                  return <BetriebskostenEmptyElement idx={ (i * 3) + innerIndex} change={
                    (idx, text) => {
                      freetextElements[idx] = {
                        text,
                        section: s.sectiontitle
                      };
                      setFreetextElements([...freetextElements]);
                    }
                  }/>
                })
              }

            </BetriebskostenSection>
          })}

          {state !== 'success' &&
          <>
            <div className="mt-3">
              {state === 'initial' && <RcButton
                onClick={() => {
                  calculate();
                  setState('calculated');
                }}
                small={true}
              >
                Berechnung starten
              </RcButton>}
              {state === 'calculated' && <RcButton
                onClick={() => {
                  calculate();
                }}
                small={true}
                style={"outline"}
              >
                Berechnung erneut starten
              </RcButton>}
            </div>

            {calculated &&
            <div className="mt-5">
              <h2>Ihr Ergebnis</h2>
              <p>
                <b>Korrekt abgerechnet:</b> {legit}
              </p>

              <p>
                <b>Nicht abgerechnet, aber gültig: </b>
                {legitButNotMarked}
              </p>

              <p>
                <b>Fehlerhaft abgerechnet (nicht gültig): </b>
                {notLegit}
              </p>

              <p className={"mt-4"}>
                Um die fehlerhaften Punkte zu erfahren, hinterlassen Sie bitte Ihre E-Mail Adresse.<br/>
                Im Zuge dessen werden auch die Ihnen zusätzlich angeführten Betriebskosten berücksichtigt.
              </p>

              <div className="rc-form-control mt-4 mb-4">
                <p>Ihre Mail-Adresse</p>
                <input ref={mailInputRef} className={styles.input} type="text"/>
              </div>

              <RcButton
                small={true}
                onClick={async () => {
                  const mail = mailInputRef.current ? mailInputRef.current.value : "";
                  await axios.post(getStrapiUrl() + "/submit-betriebskostencheck", {
                    ownElements,
                    legitElements,
                    legitButNotMarkedElements,
                    notLegitElements,
                    freetextElements,
                    mail
                  });
                  setState('success')
                }
              }>
                Ergebnis per E-Mail senden
              </RcButton>

            </div>
            }
          </>
          }

          { state === 'success' &&

            <div className={"mt-5"}>
              <h3 className={"text-primary"}>Ihre Anfrage ist angelangt</h3>
              <p>
                Vielen Dank für das Ausfüllen der Anfrage. Um bestmögliche Qualität zu gewährleisten, werden alle Anfragen per Hand kontrolliert.
              </p>

              <p className={"mt-2"}>
                Die endgültige Auswertung und Zusendung erfolgt innerhalb der nächsten paar Werkstage
              </p>
            </div>

          }
        </>
};

export default BetriebskostenCheck;
