import React from 'react';
import { Link } from "gatsby";
import * as styles from './Page.module.scss';

import Layout from "../../page-components/layout";
import { graphql } from "gatsby";
import RcMitarbeiter from "../../pages-parts/team/rcMitarbeiter"

import ChevronLeftSVG from "../../icons/fontawesome/light/chevron-left.svg";
import PageFurtherBeratung from "../../pages-parts/page/page-further-beratung"
import PageFurtherImmo from "../../pages-parts/page/page-further-immo"
import PageFurtherGutachten from "../../pages-parts/page/page-further-gutachten"
import RcDefaultPageTeaser from "../../page-components/teaser/rcDefaultPageTeaser"
import BetriebskostenCheck from "../../pages-parts/betriebskosten-check/betriebskosten-check"
import ImmoEval from "../../pages-parts/immo-eval/immo-eval"
import CompanyEval from "../../pages-parts/company-eval/company-eval"
import ImmoVermittlung from "../../pages-parts/immo-vermittlung/immo-vermittlung"
import BeratungKontakt from "../../pages-parts/beratung-kontakt/beratung-kontakt"

export default ( { data } ) => {

  const mitarbeiter = data.page.acf.mitarbeiter;
  let backLink = data.page.acf.art;

  if (backLink === 'immo') {
    backLink = "immoservice";
  }

  // nullify
  if (backLink === "keine") {
    backLink = null;
  }

  return (
    <Layout>

      <RcDefaultPageTeaser>
        { backLink &&
        <Link to={"/" + backLink} className={"d-flex align-items-center " + styles.backlink}>
          <ChevronLeftSVG className={"mr-1 svgFill"} />
          Zurück
        </Link>

        }

        <h1>{data.page.title}</h1>
        { data.page.acf.subcaption && <div dangerouslySetInnerHTML={
          {
            __html: data.page.acf.subcaption
          }
        } /> }
      </RcDefaultPageTeaser>

      <div className="container h-100 bg-white">

        <div className="row">
          <div className="col-12">

            <div className="pb-5 pt-3">
              <div className={"contentWrapper " + data.page.acf?.paragraphmargin + " content"}>
                <div dangerouslySetInnerHTML={ { __html: data.page.content }} />

                { mitarbeiter && mitarbeiter.length > 0 &&
                mitarbeiter.map(
                  (m) => {
                    return <RcMitarbeiter mitarbeiter={m} />
                  }
                )
                }

                { data.page.slug === "betriebskostencheck" && <BetriebskostenCheck /> }
                { (data.page.slug === "unternehmensbewertung" || data.page.slug === "unternehmensbewertung-2") && <CompanyEval /> }
                { (data.page.slug === "immobilienbewertung" || data.page.slug === "immobilienbewertung-2") && <ImmoEval /> }
                { data.page.slug === "immobilien-vermittlung" && <ImmoVermittlung /> }
                { data.page.slug === "beratung-kontaktformular" && <BeratungKontakt /> }

              </div>
            </div>

          </div>

        </div>


        { backLink === 'beratung' && <PageFurtherBeratung removeId={data.page.id}/> }
        { backLink === 'immoservice' && <PageFurtherImmo removeId={data.page.id}/> }
        { backLink === 'gutachten' && <PageFurtherGutachten removeId={data.page.id}/> }

      </div>
    </Layout>
  );

};

export const query = graphql`
  query wpPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      slug
      title
      content
      acf {
        art
        subcaption
        paragraphmargin
        headerimage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        
        mitarbeiter {
          description
          imageleftright
          linkedin
          mail
          phone
          quotation
          xing
          name
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        

        
      }
    }
  }
`
